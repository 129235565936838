<template>
    <div class="ubicacion">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-container>
            <v-card>
                <v-dialog v-model="modalPersona" max-width="1500px" persistent>
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <v-card-title>
                                <span class="text-h5">{{tituloPersona}} ITEM A {{editPersona.nombre}}</span>
                            </v-card-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-container>
                                <v-form ref="frmPersona" v-model="validoPersona" lazy-validation>
                                    <v-autocomplete v-model="asignado" :items="activos" chips small-chips label="Select"
                                        item-text="codigo" item-value="Uid" outlined multiple @change="addInput()">
                                        <template v-slot:selection="data">
                                            <v-chip v-bind="data.attrs" :input-value="data.selected" close
                                                @click="data.select" @click:close="remove(data.item)">
                                                <v-avatar left>
                                                    <v-img
                                                        :src="`${url_api}/files/images/${data.item.Uid}/.Metadatos/${data.item.archivo}`">
                                                    </v-img>
                                                </v-avatar>
                                                {{ data.item.codigo }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template v-if="typeof data.item !== 'object'">
                                                <v-list-item-content v-text="data.item">
                                                </v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-avatar tile>
                                                    <v-img :src="imgs" style="width: 100px; height:100px"
                                                        v-if="!data.item.archivo" />
                                                    <img :src="`${url_api}/files/images/${data.item.Uid}/.Metadatos/${data.item.archivo}`"
                                                        v-else>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.codigo">
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.group">
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                    <div class="inputArea" v-for="nota in notas" :key="nota.Uid">
                                        <v-textarea prepend-inner-icon="" :id="nota.Uid" :label="nota.label"
                                            :placeholder="nota.placeholder" v-model="nota.value" auto-grow rows="10"
                                            row-height="15" outlined clearable>
                                        </v-textarea>
                                    </div>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="cancelarPersona" color="error" class="mr-4">Cancelar
                                        </v-btn>
                                        <v-btn @click="guardarPersona" color="primary">Guardar</v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-card-title>
                    <v-toolbar-title>
                        ASIGNAR / DESASIGNAR
                    </v-toolbar-title>
                    <v-divider class="mx-3" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar:" outlined hide-details
                        clearable></v-text-field>
                </v-card-title>

                <!-- tabla -->
                <v-data-table :headers="headersPersona" :items="personas" :search="search" :footer-props="{
            showFirstLastPage: true, 
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-text': 'N° Paginas',
            'items-per-page-all-text':'Todos',
            pageText: '{1} de {2}',
            }" class="elevation-1">
                    <template v-slot:item.numero="{item, index}">
                        {{ ++index  }}
                    </template>
                    <template v-slot:item.acciones="{ item }">
                        <span v-for="permiso in editPerfil.permiso" :key="permiso">
                            <v-btn v-if="permiso == 2 || permiso == 0" small class="ma-1" color="blue" @click="editarPersona(item)">
                                <v-icon small color="white">
                                    mdi-account-details
                                </v-icon>
                            </v-btn>
                            <v-btn v-if="permiso == 3 || permiso == 0" small class="ma-1" color="error" @click="eliminarPersona(item)">
                                <v-icon small color="white">
                                    mdi-account-minus
                                </v-icon>
                            </v-btn>
                        </span>
                        <v-btn small class="ma-1" color="warning" @click="list(item)">
                            <v-icon small color="white">
                                mdi-file-document
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="listarPersona">
                            Recargar
                        </v-btn>
                    </template>
                </v-data-table>
                <!-- tabla fin -->
            </v-card>
        </v-container>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import imagePre from "@/assets/yedi.png";
    export default {
        name: "persona",
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,

            notas: [],
            lent: 0,
            h: 2,
            imgs: imagePre,
            asignado: null,
            activos: [],
            activosAsign: [],
            activosTotal: [],
            des: null,
            search: '',
            editPerfil: {},
            // variables persona 
            UidPersona: null,
            buscarPersona: '',
            validoPersona: true,
            modalPersona: false,
            modalEliminarPersona: false,
            headersPersona: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / País',
                    align: 'start',
                    value: 'nombre',
                },

                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            personas: [],
            reglaNombrePersona: [
                v => !!v || 'El nombre de la persona es obligatorio.'
            ],
            editedPersonaIndex: -1,

            editPersona: {
                nombre: '',
            },
            defaultPersona: {
                nombre: '',
            },
            // fin variables persona
        }),

        computed: {
            ...mapState(["token", "usuario"]),
            // titulo modal persona 
            tituloPersona() {
                return this.des === null ? 'ASIGNAR' : 'DESASIGNAR'
            },
        },

        watch: {
            // modal persona
            modalPersona(val) {
                val || this.cancelarPersona()
            },
            modalEliminarPersona(val) {
                val || this.cerrarEliminarPersona()
            },
            // fin modal persona
        },

        created() {
            this.editPerfil = JSON.parse(this.usuario)
            // listar personas
            this.listarPersona();
            // listar personas
            // this.listarActivos()
            //this.listarActivosAsign();
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            remove(item) {
                const index = this.asignado.indexOf(item.Uid)
                if (index >= 0) this.asignado.splice(index, 1)
                if (index >= 0) this.notas.splice(index, 1)
            },

            addInput() {
                if (this.notas.length > 0) {
                    if (this.lent <= this.asignado.length) {
                        let indice = this.activos.findIndex(activo => activo.Uid === this.asignado[this.asignado
                            .length -
                            1]);
                        this.notas.push({
                            id: this.asignado[this.asignado.length - 1],
                            Uid: `asign${this.asignado[this.asignado.length - 1]}`,
                            asigna: JSON.parse(this.usuario).usuario,
                            usuario: this.UidPersona,
                            value: '',
                            placeholder: this.activos[this.asignado.length - 1].notaDesasign,
                            label: this.activos[indice].codigo,
                        });
                    } else {
                        var a = 0;
                        this.notas.forEach(element => {
                            a = this.asignado.indexOf(element.id)
                            if (a == -1) {
                                this.notas.splice(this.notas.indexOf(element), 1);
                                return;
                            }
                        });
                    }
                } else {
                    let indice = this.activos.findIndex(activo => activo.Uid === this.asignado[this.asignado.length -
                        1]);
                    this.notas.push({
                        id: this.asignado[this.asignado.length - 1],
                        Uid: `asign${this.asignado[this.asignado.length - 1]}`,
                        asigna: JSON.parse(this.usuario).usuario,
                        usuario: this.UidPersona,
                        value: '',
                        placeholder: this.activos[this.asignado.length - 1].fechaDesasig + ' : ' + this.activos[
                            this.asignado.length - 1].notaDesasign,
                        label: this.activos[indice].codigo,
                    });
                }
                this.lent = this.asignado.length
            },

            // CRUD paises 
            async listarPersona() {
                try {
                    await fetch(`${this.url_api}/persona/persona`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => {
                            console.error('Error:', error)
                        })
                        .then(response => {
                            if (response.body) {
                                const luistaPersona = [];
                                response.body.forEach(persona => {
                                    luistaPersona.push({
                                        Uid: persona.Uid,
                                        nombre: `${persona.nombre} ${persona.apellido}`,
                                        identificacion: persona.identificacion
                                    })
                                })
                                this.personas = luistaPersona;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarActivos() {
                try {
                    await fetch(`${this.url_api}/item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.activosTotal = response.body;
                                response.body.forEach(element => {
                                    if (element.asignacion == 0) {
                                        this.activos.push(element);
                                    }
                                });
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarActivosAsign() {
                try {
                    await fetch(`${this.url_api}/asignacion`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.activos = response.body.filter(element => element.asignado == this
                                    .UidPersona)
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            editarPersona(persona) {
                this.activos = [];
                this.listarActivos();
                this.editedPersonaIndex = this.personas.indexOf(persona)
                this.editPersona = Object.assign({}, persona)
                this.modalPersona = true
                this.UidPersona = persona.Uid;
            },

            eliminarPersona(persona) {
                this.activos = [];
                this.listarActivosAsign();
                this.editedPersonaIndex = this.personas.indexOf(persona)
                this.editPersona = Object.assign({}, persona)
                this.modalPersona = true
                this.des = 1;
                this.UidPersona = persona.Uid;
            },

            cancelarPersona() {
                this.notas = []
                this.$refs.frmPersona.reset()
                this.$refs.frmPersona.resetValidation()
                this.des = null;
                this.modalPersona = false;
                this.$nextTick(() => {
                    this.editPersona = Object.assign({}, this.defaultPersona)
                    this.editedPersonaIndex = -1
                })
            },

            cerrarEliminarPersona() {
                this.modalEliminarPersona = false
                this.$nextTick(() => {
                    this.editPersona = Object.assign({}, this.defaultPersona)
                    this.editedPersonaIndex = -1
                })
            },

            async list(persona) {
                try {
                    this.activos = [];
                    this.UidPersona = persona.Uid;
                    await this.listarActivosAsign();
                    this.activos.push({
                        'identificacion': this.editPerfil.identificacion,
                        'nombre': this.editPerfil.nombre,
                        'apellido': this.editPerfil.apellido,
                        'cargo': this.editPerfil.cargo
                    });
                    await fetch(`${this.url_api}/asignacion/formato`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            },
                            body: JSON.stringify(this.activos)
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                window.location.href = `${this.url_api}/files/formatos/${response.body}`
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async guardarPersona() {
                if (this.$refs.frmPersona.validate()) {
                    if (this.des == 1) {
                        try {
                            await fetch(`${this.url_api}/asignacion/desasignacion/`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.notas)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Item(s) desasignado(s) con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.personas[this.editedPersonaIndex], this
                                            .editPersona);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al desasignar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidPersona = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/asignacion/`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.notas)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Item(s) asignado(s) con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarPersona();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al asignar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarPersona();
                    this.des = null;
                    this.activos = [];
                }
            },
            // fin CRUD personas
        },
    }
</script>